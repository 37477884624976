
/* Default font size for all screens */
body {
    font-size: 16px;
  }
  
  /* Adjust font size for screens smaller than 768px */
  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }
  }

  .container{padding: 0;}
  .container-fluid{padding: 0;}

  @media(max-width:800px) {
    .display-2{
        font-size: 25px;

    }
  }

  p{
    color: black;
  }
  

  .btn:hover{
    background-color: #FFD500 !important;
  }

  /* Dashboard.css */
/* styles.css */
.welcome-section {
  position: relative;
  height: 400px; /* Adjust the height as needed */
  overflow: hidden;
}

.background-image {
  background-image: url('./jsx/img/dashboardBackground.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome-message {
  min-width: 50vw;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.welcome-message h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.welcome-message p {
  font-size: 18px;
}

@media(max-width:600px){
  .welcome-message h1{
    font-size: 20px;
  }
  .accordion{
    width: 100vw !important;
    text-align: center;
  }
}